import { bindable, inject }  from 'aurelia-framework';
import { I18N }              from 'aurelia-i18n';
import { BaseListViewModel } from 'base-list-view-model';
import { FilterFormSchema }  from 'modules/management/specifications/sectors/filter-form-schema';
import { AppContainer }      from 'resources/services/app-container';
import { Downloader }        from 'resources/services/downloader';
import { SectorsRepository } from './services/repository';

@inject(AppContainer, SectorsRepository, I18N, Downloader, FilterFormSchema)
export class ListSectors extends BaseListViewModel {

    listingId = 'management-specifications-sectors-listing';

    @bindable headerTitle    = 'listing.management.specifications.sectors';
    @bindable newRecordRoute = 'management.specifications.sectors.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.sectors.manage',
            'management.specifications.sectors.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.specifications.sectors.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.sectors.manage', 'management.specifications.sectors.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.specifications.sectors.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.sectors.manage', 'management.specifications.sectors.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.sectors.manage', 'management.specifications.sectors.delete']),
            },
            options:         [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.sectors'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.sectors.manage', 'management.specifications.sectors.view']),
                },
            ],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'description',
                    name:  'sector_translations.description',
                    title: 'form.field.description',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'sectors.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

}
