import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ListingsRepository extends BaseRepository {
    baseUrl = 'management/specifications/listings';

    /**
     * Duplicates a record
     *
     * @param duplicatingId
     * @param data
     *
     * @returns {*}
     */
    duplicate(duplicatingId, data) {
        return this.httpClient.post(`${this.baseUrl}/${duplicatingId}/duplicate`, data);
    }
}
